import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { STRINGS } from "src/strings/strings";
import { styles, useStyles } from "../styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ic_sort from "../../../../../public/assets/icons/navbar/ic_import.svg";
import SearchBar from "material-ui-search-bar";
import SearchIcon from "@mui/icons-material/Search";
import ic_import from "../../../../assets/images/ic_import.svg";
// import Router, { useRouter } from "next/router";

function HeaderSection(props: any) {
  const [searchBar, setSearchBar] = useState("");
  const [date, setDate] = useState<Date | null>(null);

  const handleChangeDate = () => {
    // setFilterService(event.target.value);
  };

  const classes = styles()
  console.log(props.type.type,'props!!!!!!');

  const type = props.type.type
  

  return (
    <Grid>
      <Grid container style={{display: "flex", justifyContent: "space-between"}}>
        <Grid>
          <Typography style={useStyles.root} variant="subtitle2">
            Morrison & Foester
          </Typography>
        </Grid>
        <Grid>
          <Grid style={useStyles.exportBtnStyle}>
            <img src={ic_import} style={{ marginRight: 10 }}></img>
            <Typography variant="subtitle2">Export</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={useStyles.flexRow}>
          <Typography variant="subtitle2" style={{color: "#212B36"}}>{type === "Reimbursement Details" ? "Reimbursement Details" :  "Reimbursement History"}</Typography>
          <span className={classes.dotStyle}></span>
          <Typography variant="subtitle2" style={{color: "#637381"}}>Employer Reimbursement</Typography>
      </Grid>
      <Grid container style={{ marginTop: 30 }}>
        <Grid xs={4} style={useStyles.flexColumn}>
          <Typography variant="subtitle1" style={useStyles.dateTextStyle}>
            05/23/2022 - 05/30/2022
          </Typography>
          <Typography variant="subtitle2" style={useStyles.labelTextStyle}>
            Showing the reimbursement history in selected date duration
          </Typography>
        </Grid>
        <Grid xs={4} style={useStyles.flexColumn}>
          <Typography variant="subtitle2" style={useStyles.amountTextStyle}>
            $ 1028
          </Typography>
          <Typography variant="subtitle2" style={useStyles.labelTextStyle}>
            Total contributed amount in selected date range
          </Typography>
        </Grid>
        <Grid xs={4}>
          <TextField
            style={{ width: "100%" }}
            id="input-with-icon-textfield"
            placeholder="Search by employer name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#637381" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          {/* <SearchBar
            value={searchBar}
            style={{border: "1px solid #919EAB"}}
            placeholder="Search by employer name"
            
            // onChange={(newValue) => this.setState({ value: newValue })}
            // onRequestSearch={() => doSomethingWith(this.state.value)}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderSection;
