import React from 'react'
import {
    Table,
    Button,
    Tooltip,
    Divider,
    TableBody,
    Container,
    IconButton,
    TableContainer,
    TablePagination,
    FormControlLabel,
    Grid,
    Typography,
  } from '@mui/material';
 /*  import Iconify from '../../components/Iconify'; */
import { TableHeadCustom, TableSelectedActions } from 'src/components/table';
import TableRows from './tableRow';
import { detailedViewTableHead, detailedViewTableRowData } from 'src/constants/reimbursementEnum';

function ListView(props: any) {

   
  return (
    <div style={{marginTop: 40}}>
         {/* <Scrollbar> */}
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {/* {selected.length > 0 && ( */}
            {/*   )} */}

              <Table /* size={dense ? 'small' : 'medium'} */>
                <TableHeadCustom
                  /* order={order}
                  orderBy={orderBy} */
                  headLabel={detailedViewTableHead}
                  /* rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort} */
                  /* onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  } */
                />

                <TableBody>
                  {detailedViewTableRowData && detailedViewTableRowData.map((row: any) => ( 
                      <TableRows
                      key={row.id}
                      row={row}
                        /* selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.name)} */
                      />
                     ))}

                  {/* <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  /> */}

                  {/* <TableNoData isNotFound={isNotFound} /> */}
                </TableBody>
              </Table>
            </TableContainer>
          {/* </Scrollbar> */}
    </div>
  )
}

export default ListView