export const detailedViewTableHead = [
    { id: 'name', label: 'Employee Name', align: 'left' },
    { id: 'date', label: 'UBC ID', align: 'left' },
    { id: 'numberOfEmployees', label: 'Level(Apprenticeship)', align: 'left' },
    { id: 'amount', label: 'Course Started Date', align: 'left' },
    { id: 'amount', label: 'Contribution', align: 'left' },
    { id: '' },
  ];

export const detailedViewTableRowData = [{
    id: 1,
    name: "David Luiz",
    UBC: "BNS538",
    level: "A",
    date: "05/20/2022",
    contribution: "$ 100"
},
{
    id: 1,
    name: "Deja Brady",
    UBC: "BNS528",
    level: "B",
    date: "15/20/2022",
    contribution: "$ 100"
},
{
    id: 1,
    name: "Adam Johnson",
    UBC: "BNS768",
    level: "C",
    date: "25/20/2022",
    contribution: "$ 100"
},
{
    id: 1,
    name: "Luis Enrique",
    UBC: "BNS578",
    level: "A",
    date: "18/20/2022",
    contribution: "$ 100"
}]