import { makeStyles } from "@material-ui/core";

export const useStyles: any = {
  root: {
    color: "#212B36",
    fontSize: 24,
    fontWeight: 600,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  exportBtnStyle: {
    border: "1px solid #054832",
    borderRadius: 8,
    width: 118,
    height: 56,
    color: "#054832",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  labelTextStyle: {
    color: "#637381",
    fontSize: 13,
    fontWeight: 400,
  },
  dateTextStyle: {
    color: "#40AA87",
    fontSize: 24,
    fontWeight: 600,
  },
  amountTextStyle: {
    color: "#212B36",
    fontSize: 24,
    fontWeight: 600,
  },
  tableBtnStyle: {
    color: "#054832",
    border: "1px solid #637381",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    width: 140,
    cursor: "pointer",
  },
  checkBtnStyle: {
    color: "#fff",
    background: "#054832 0% 0% no-repeat padding-box",
    border: "1px solid #637381",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 400,
    height: 40,
    width: 140,
    cursor: "pointer",
  },
  reimbursedAmount: {
    display: "flex",
    paddingRight: 10,
    alignItems: "center",
  },
  reimbursementCard: {
    display: "flex",
    flexDirection: "column",
    background: "#D9FFF3",
    padding: "12px 25px",
  },
  reimbursementAmount: {
    textAlign: "center",
    justifyContent: "center",
    color: "#054832",
    fontFamily: "Public Sans",
    fontSize: "28px",
    fontWeight: 600,
  },
  reimbursementText: {
    textAlign: "center",
    justifyContent: "center",
    color: "#054832",
    fontFamily: "Public Sans",
    fontSize: "13px",
    fontWeight: 600,
  },
};

export const styles = makeStyles({
  standingEmpText: {
    color: "#054832",
    fontSize: 18,
    fontWeight: 600,
  },
  holdEmpText: {
    color: "#FFB902",
    fontSize: 18,
    fontWeight: 600,
  },
  labelText: {
    color: "#637381",
    fontSize: 13,
  },
  dotStyle: {
    height: 8,
    width: 8,
    backgroundColor: "#637381",
    borderRadius: "50%",
    display: "inline-block",
    margin: "8px 10px 0px 10px",
  },
  contributionSection: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#D9FFF3",
    color: "#054832",
    alignItems: "center",
    width: "30%",
  },
});
