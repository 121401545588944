import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
import { sentenceCase } from 'change-case';
import { Grid } from '@material-ui/core';
import { useStyles } from '../styles';
import { UserManager } from '../../../../@types/user';
import Label from '../../../../components/Label';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';

type Props = {
  row: UserManager;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function TableRows(props: any) {

  const theme = useTheme();
  const { name, UBC, level, date, contribution} = props.row; 
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  return (
    <TableRow hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={""} sx={{ mr: 2 }} />
        <Grid style={{display: "flex", flexDirection: "column"}}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
        <Typography variant="subtitle2" style={{color: "#637381", fontSize: 14}}>301 Saratoga, Delware, OH 4...</Typography>
        </Grid>
      </TableCell>

      <TableCell align="left">{UBC}</TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {level}
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
            {date}
      </TableCell>

      <TableCell>
              <Typography>{contribution}</Typography>
      </TableCell>
    </TableRow>
  );
}
