import React from 'react'
import HeaderSection from './headerSection'
import ListView from './listView'
import { useLocation } from 'react-router-dom'

function ReimbursementDetailedView() {
    const location = useLocation()
  const type = location.state
  console.log(type, 'from');
  
  return (
    <div>
        <HeaderSection type={type} />
        <ListView type={type} />
    </div>
  )
}

export default ReimbursementDetailedView